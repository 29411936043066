import {useMemo} from 'react';
import {graphql, useStaticQuery} from 'gatsby';

export const useRetrieveAlbumsPagePaths = () => {
    const result = useStaticQuery(graphql`
        query PageQuery {
            allSitePage (
                filter: {
                    context: {
                        page: {
                            pageModel: {
                                in: ["nos_histoires_produit", "catalog_flam"]
                            }
                        }
                    }
                }
            ) {
                nodes {
                    path
                }
            }
        }
    `);

    const paths = useMemo(
        () =>
            result?.allSitePage?.nodes.reduce((acc: string[], curr: {path: string}) => {
                const {path} = curr;
                acc.push(path);
                return acc;
            }, []),
        [result],
    );

    return paths;
};

export default useRetrieveAlbumsPagePaths;
