import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {cssBorder, cssText} from '@ohoareau/css-utils';
import {Grid, makeStyles} from '@material-ui/core';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import clsx from 'clsx';
import {FormProvider, useForm} from 'react-hook-form';
import {StripeCardElement, StripeError} from '@stripe/stripe-js';
import {Trans} from 'react-i18next';
import {
    address,
    AddressForm,
    AddressTypeEnum,
    Button,
    Checkbox,
    CreditCardForm,
    ErrorPanel,
    Row,
    ShadowedPanel,
    Spinner,
    useLuniiNavigation, useLuniiPage,
    useLuniiSpa,
    useLuniiTranslation,
    useLuniiUser,
    useSubscriptionGift,
    WarningPanel,
} from '../../../ui';
import {SubscriptionGiftRecap} from './components/SubscriptionGiftRecap';
import {
    LuniiSubscriptionGiftCheckoutContext,
    SubscriptionGiftCheckoutActionType,
} from '../../../contexts';
import {
    SubscriptionGiftMetadata,
    SubscriptionSubmitOptionsEnum,
} from '../../../definitions/subscriptionGift';
import {usePageTracking} from '../../../hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    panel: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(3),
        backgroundColor: 'white',
        ...cssBorder(theme, 'push'),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    },
    spinner: {
        '& p': {
            ...cssText(theme, 'standard', 'title_2'),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        margin: theme.spacing(0, 0, 3),
        [theme.breakpoints.up('sm')]: {
            ...cssText(theme, 'standard', 'large_title', 'center', undefined, '#2C3637'),
            margin: theme.spacing(0, 0, 5),
        },
    },
    heading: {
        ...cssText(theme, 'standard', 'large_title'),
        margin: theme.spacing(1, 0, 4),
        textAlign: 'left',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
            margin: theme.spacing(2, 0, 4),
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
    },
    formTitle: {
        ...cssText(theme, 'standard', 'title_2'),
        [theme.breakpoints.up('sm')]: {
            ...cssText(theme, 'standard', 'title_1'),
        },
    },
    paymentform: {
        '& h2': {
            margin: theme.spacing(0, 0, 1),
            ...cssText(theme, 'standard', 'title_2'),
            [theme.breakpoints.up('sm')]: {
                ...cssText(theme, 'standard', 'title_1'),
            },
        },
        '& p': {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
    creditCardForm: {
        padding: 0,
        '& .reassuranceTitle': {
            ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#716C5E'),
        },
        '& .reassuranceBody': {
            ...cssText(theme, 'standard', 'secondary_body', undefined, undefined, '#716C5E'),
        },
    },
    checkboxes: {
        '& >*': {
            margin: theme.spacing(0, 0, 2),
        },
        '& label': {
            alignItems: 'flex-start',
        },
        marginBottom: theme.spacing(2),
    },
    checkboxLink: {
        ...cssText(theme, 'standard', 'button_1_plain'),
        fontWeight: '800',
        margin: theme.spacing(0, 0, 1, 0),
    },
    actions: {
        width: '100%',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        '&>*': {
            width: '100%',
            '&:not(:first-child)': {
                margin: theme.spacing(1, 0, 0, 0),
            },
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(2),
            '&>*': {
                width: 'auto',
                '&:not(:first-child)': {
                    margin: `${theme.spacing(0, 0, 0, 2)} !important`,
                },
            },
        },
    },
    errors: {
        width: '100%',
    },
    errorPanel: {
        marginBottom: theme.spacing(1),
        '&:last-child()': {
            marginBottom: theme.spacing(2),
        },
    },
}));

export function BillingScreen() {
    usePageTracking(true, 'abonnement_offir_paiement');
    const classes = useStyles();
    const [state, dispatch] = useContext(LuniiSubscriptionGiftCheckoutContext);
    const [cardComplete, setCardComplete] = useState<boolean>(false);
    const [payable, setPayable] = useState<boolean>(false);
    const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);
    const [checkboxes, setCheckboxes] = useState({
        tos: false,
        franceonly: false,
    });
    const [paymentIntentConfirmationError, setPaymentIntentConfirmationError] = useState<
        StripeError | undefined
    >(undefined);
    const {t, i18n, exists} = useLuniiTranslation();
    const {user} = useLuniiUser();
    const stripe = useStripe();
    const {locale} = useLuniiPage() || {};
    const elements = useElements();
    const formCreditCardOwner = useForm({mode: 'onChange'});
    const {navigate} = useLuniiSpa();
    const {goPageByModel} = useLuniiNavigation();
    const [{buyCouponExecute}, {buyCouponResult}] = useSubscriptionGift();

    // User billing address
    const billingInfos = useMemo(
        () => user?.billingInfos?.find((adr: address) => adr.locale === user?.locale),
        [user?.billingInfos, user?.locale],
    );

    const handlePay = useCallback(async () => {
        if (paymentInProgress) {
            return;
        }
        setPaymentInProgress(true);
        const {sendingDate, ...couponMetadata}: Partial<SubscriptionGiftMetadata> = state.selected.metadata || {};
        try {
            if (buyCouponExecute && stripe && elements && billingInfos) {
                const card = elements.getElement('card') as StripeCardElement;
                const name = formCreditCardOwner.getValues('cardOwner');
                if (!card) {
                    return;
                }
                const executeResult = await buyCouponExecute({
                    variables: {
                        data: {
                            countryCode: locale,
                            stripeProductId: state.selected?.product?.id,
                            ...(state.selected.submitMode ===
                                SubscriptionSubmitOptionsEnum.BY_EMAIL && {
                                    ...couponMetadata,
                                    ...(sendingDate && {
                                        sendingDate,
                                    }),
                                }),
                        },
                    },
                });
                const retrievedClientSecret =
                    executeResult.data?.buySubscriptionCoupon?.client_secret;
                if (retrievedClientSecret) {
                    const {paymentIntent, error} = await stripe.confirmCardPayment(
                        retrievedClientSecret,
                        {
                            payment_method: {
                                card,
                                billing_details: {
                                    address: {
                                        city: billingInfos.city,
                                        country: billingInfos.locale.substring(3),
                                        line1: billingInfos.address1,
                                        postal_code: billingInfos.zipCode,
                                    },
                                    name,
                                },
                            },
                        },
                    );
                    if (error) {
                        setPaymentIntentConfirmationError(error);
                    } else {
                        dispatch({type: SubscriptionGiftCheckoutActionType.CHECKOUT_SUCCESS});
                        await navigate('/confirmation/', {
                            state: {subscriptionGiftCheckoutSuccess: true},
                        });
                        setPaymentInProgress(false);
                    }
                }
            }
        } catch {
            setPaymentInProgress(false);
        }
    }, [
        paymentInProgress,
        state.selected?.metadata,
        state.selected?.product?.id,
        state.selected?.submitMode,
        buyCouponExecute,
        stripe,
        elements,
        billingInfos,
        formCreditCardOwner,
        dispatch,
        navigate,
    ]);

    const onCardComplete = () => {
        setCardComplete(true);
    };

    useEffect(() => {
        if (
            cardComplete &&
            billingInfos &&
            Object.values(checkboxes).every((checked) => checked) &&
            !payable
        ) {
            setPayable(true);
        } else if (
            (!cardComplete ||
                !billingInfos ||
                Object.values(checkboxes).some((checked) => !checked)) &&
            payable
        ) {
            setPayable(false);
        }
    }, [cardComplete, payable, billingInfos, checkboxes]);

    const handlePrevious = () => {
        navigate('/');
    };

    const handleCheckboxChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, checked} = e.target;
        setCheckboxes((checkboxesState: any) => ({
            ...checkboxesState,
            [id]: checked,
        }));
    };
    const handleLegalClick = () => {
        if (goPageByModel) {
            goPageByModel('mentions_legales', true);
        }
    };
    if (!state?.selected?.product) {
        return null;
    }
    return (
        <Row
            padding="custom2"
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.root}
        >
            <Grid
                item
                md={6}
                sm={8}
                xs={12}
            >
                {paymentInProgress && (
                    <Spinner fixed className={classes.spinner}>
                        <p>{t('checkout_payment_wait')}</p>
                    </Spinner>
                )}
                <div className={classes.title}>
                    <Trans
                        i18n={i18n}
                        i18nKey="subscriptiongift_title_step_2"
                    >
                        ...<span>...</span>...
                    </Trans>
                </div>
                <SubscriptionGiftRecap
                    className={classes.panel}
                    subscription={state.selected}
                    submitMode={state.selected?.submitMode}
                    metadata={state.selected?.metadata}
                />
                <ShadowedPanel className={classes.panel}>
                    <AddressForm
                        key="billing"
                        title={
                            <span className={classes.formTitle}>
                                {t('subscriptiongift_billing_address_title')}
                            </span>
                        }
                        type={AddressTypeEnum.Billing}
                        locale={user?.locale!}
                    />
                </ShadowedPanel>
                <ShadowedPanel className={clsx(classes.panel, classes.paymentform)}>
                    <h2>{t('subscriptiongift_paymentform_title')}</h2>
                    <FormProvider {...formCreditCardOwner}>
                        <CreditCardForm
                            className={classes.creditCardForm}
                            onCardComplete={onCardComplete}
                        />
                    </FormProvider>
                </ShadowedPanel>
                <div className={classes.checkboxes}>
                    <Checkbox
                        id="tos"
                        name="tos"
                        status={checkboxes.tos}
                        content={
                            <Trans
                                i18n={i18n}
                                i18nKey="subscriptiongift_checkbox_terms"
                            >
                                ...
                                <span
                                    role="link"
                                    tabIndex={0}
                                    onKeyDown={handleLegalClick}
                                    onClick={handleLegalClick}
                                    className={classes.checkboxLink}
                                >
                                    ...
                                </span>
                                ...
                            </Trans>
                        }
                        onChange={handleCheckboxChanges}
                    />
                    <Checkbox
                        id="franceonly"
                        name="franceonly"
                        alignItems="flex-start"
                        status={checkboxes.franceonly}
                        content={t('subscriptiongift_checkbox_franceonly', {email: user?.email})}
                        onChange={handleCheckboxChanges}
                    />
                </div>
                <div className={classes.errors}>
                    {paymentIntentConfirmationError && (
                        <ErrorPanel
                            className={classes.errorPanel}
                            group="stripe"
                            error={paymentIntentConfirmationError}
                        />
                    )}
                    {buyCouponResult.error && (
                        <ErrorPanel
                            className={classes.errorPanel}
                            group="subscription_gift"
                            error={buyCouponResult.error}
                        />
                    )}
                </div>
                {exists('checkout_offer_subscription_cart_global_message') && (
                    <WarningPanel
                        type="message"
                        transKey="checkout_offer_subscription_cart_global_message"
                    />
                )}
                <div className={classes.actions}>
                    <Button
                        onClick={handlePrevious}
                        color="secondary"
                    >
                        {t('subscriptiongift_navigate_previous')}
                    </Button>
                    <Button
                        onClick={handlePay}
                        color="primary"
                        disabled={paymentInProgress || !payable}
                    >
                        {t('subscriptiongift_navigate_pay')}
                    </Button>
                </div>
            </Grid>
        </Row>
    );
}

export default BillingScreen;
