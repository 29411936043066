import React, {useRef, useMemo, useEffect, useState} from 'react';
import {useLocation, HistoryLocation} from '@reach/router';
import {cssFont, cssText} from '@ohoareau/css-utils';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Loadable from '@loadable/component';
import Grid from '@material-ui/core/Grid';
import {useLuniiStorage, useLuniiPurchaseGetApi, useLuniiTranslation, useLuniiUser, useLuniiNavigation,
    Row, useLuniiCart, cart, useLuniiCheckoutTracking, ProductTableNameEnum, ElevatedPanel, DynamicIcon} from '../../../ui';
import {CheckoutLayout} from '../../layouts/CheckoutLayout';
import {storageKeys} from '../../../../configs/site';
import {Illustration} from '../../molecules/v2/Illustration';
import {Button} from '../../molecules';
import {button_theme} from '../../../types';

const SuccessConfetti = Loadable(() => import('../commons/components/SuccessConfetti'));

const useStyles = makeStyles((theme) => ({
    root: {
    },
    grid: {
    },
    confetti: {
        top: 0,
        left: 0,
    },
    left: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, '#063646'),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    description: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#1A6F8E'),
        marginBottom: theme.spacing(4),
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
            margin: '0 auto 44px auto',
        },
    },
    infosTitle: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#2C3637'),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'title_2'),
        },
    },
    panel: {
        padding: theme.spacing(4),
        borderRadius: 16,
    },
    infosContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 24,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    contentTitle: {
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#2C3637'),
        marginBottom: 4,
    },
    contentDescription: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
    },
    svg: {
        marginRight: theme.spacing(1),
        minWidth: 24,
        minHeight: 24,
    },
    button: {
      marginTop: 12,
    },
}));

export function ConfirmationScreen() {
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const location: HistoryLocation = useLocation();
    const [, , resetCart] = useLuniiCart();
    const {user, setUser, refreshUser} = useLuniiUser();
    const {goCheckout, goCatalog} = useLuniiNavigation();
    const storage = useLuniiStorage();
    const [userRefreshed, setUserRefreshed] = useState<boolean>(false);
    const firstRender = useRef(true);
    const dataLayerCheckoutHitSent = useRef(false);
    const dataLayerCheckoutEvent = useLuniiCheckoutTracking({envModel: 'confirmation_achat'});

    const purchaseId: string = useMemo<string>(() => {
        if (location?.state?.purchaseId) return location.state.purchaseId;
        if (location?.search && undefined !== window) {
            const params = new URLSearchParams(location.search);
            return params.get('purchase_id') || '';
        }
        return '';
    }, []);

    const lastPurchaseId = useMemo(() => storage?.getItem(storageKeys.lastPurchase), []); // Used to prevent sending twice the same analytics hit
    const savedCart: cart | undefined = useMemo<cart | undefined>(() => {
        if (location?.state?.cart) {
            return location.state.cart;
        }
        return storage?.getItem(storageKeys.cart);
    }, []);

    const {data: purchaseData} = useLuniiPurchaseGetApi({
        variables: {
            id: purchaseId,
        },
    });

    // save items before reset cart
    useEffect(() => {
        if (firstRender.current) {
            (async () => {
                await refreshUser();
                setUserRefreshed(true);
            })();

            // if cart is empty go back
            resetCart();
            firstRender.current = false;
            if (((savedCart || {}).items || []).length === 0 && goCheckout) {
                goCheckout();
            }
        }
    }, [firstRender, savedCart, goCheckout, resetCart, refreshUser]);

    useEffect(() => {
        if (
            purchaseData && // the gql request has been emitted
            purchaseData.getPurchase && // a purchase has been retrieved
            !dataLayerCheckoutHitSent.current && // the GA hit has not been emitted yet (re-render)
            purchaseData.getPurchase.purchaseNumber !== lastPurchaseId && // the GA hit has not been emitted yet (page reload)
            userRefreshed
        ) {
            dataLayerCheckoutHitSent.current = true;
            storage?.setItem(storageKeys.lastPurchase, purchaseData.getPurchase.purchaseNumber);
            dataLayerCheckoutEvent(savedCart?.items, purchaseData.getPurchase);
            // NB: Manual update of user.ownedPacksRefereunces because the packs are added to the
            // library asynchronously once the server webhooks are resolved
            const purchasedPacks: string[] = (savedCart?.items || []).reduce((packs, item) => {
                if (item.type === ProductTableNameEnum.Packs) packs.push(item.reference);
                return packs;
            }, [] as string[]);
            const ownedPacksReferences = [...(user?.ownedPacksReferences || []), ...purchasedPacks];
            setUser({
                ...user,
                ownedPacksReferences: Array.from(new Set(ownedPacksReferences)),
            });
        }
    }, [
        purchaseData,
        savedCart,
        storage,
        dataLayerCheckoutEvent,
        dataLayerCheckoutHitSent,
        lastPurchaseId,
        userRefreshed,
        user,
        setUser,
    ]);
    const infos = [
        {
            title: t('checkout_confirmation_next_steps_subtitle1'),
            description: t('checkout_confirmation_next_steps_text1'),
            svg: 'library',
        },
        {
            title: t('checkout_confirmation_next_steps_subtitle2'),
            description: t('checkout_confirmation_next_steps_text2'),
            svg: 'box',
        },
        {
            title: t('checkout_confirmation_next_steps_subtitle3'),
            description: t('checkout_confirmation_next_steps_text3'),
            svg: 'book',
            buttonLabel: t('checkout_confirmation_catalog_btn'),
            buttonUrl: goCatalog,
            buttonTheme: 'primary',
            buttonSize: 'small',
            buttonIcon: 'next',
            buttonIconPosition: 'right',
        },
    ]
    return (
        <CheckoutLayout>
            <Row rootClassName={classes.root} justify='space-between' alignItems='flex-start'>
                <Grid lg={4} md={4} xs={12} className={classes.left}>
                    <div className={classes.title}>{t('checkout_confirmation_title')}</div>
                    <div className={classes.description}>{t('checkout_confirmation_description')}</div>
                    <Illustration className={classes.image} name='checkout_confirmation_image_v2'/>
                </Grid>
                <Grid lg={7} md={7} xs={12}>
                    <ElevatedPanel className={classes.panel}>
                        <div className={classes.infosTitle}>{t('checkout_confirmation_next_steps')}</div>
                        {infos.map(({description, title, svg, buttonLabel, buttonUrl, buttonTheme, buttonSize, buttonIcon, buttonIconPosition}) => (
                            <div className={classes.infosContainer}>
                                <DynamicIcon type={`${svg}-24`} className={classes.svg} width={24} height={24}/>
                                <div>
                                    <div className={classes.contentTitle}>{title}</div>
                                    <div className={classes.contentDescription}>{description}</div>
                                    {buttonLabel && buttonUrl && (
                                        <Button
                                            className={classes.button}
                                            text={buttonLabel}
                                            url={buttonUrl}
                                            theme={buttonTheme as button_theme}
                                            size={buttonSize as 'medium' | 'small'}
                                            icon={buttonIcon}
                                            iconPosition={buttonIconPosition as 'left' | 'right'}/>
                                    ) || false}
                                </div>
                            </div>
                        ))}
                    </ElevatedPanel>
                </Grid>
                <SuccessConfetti className={classes.confetti} />
            </Row>
        </CheckoutLayout>
    );
}

export default ConfirmationScreen;
