import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Trans} from 'react-i18next';
import {cssText, cssBox, cssFgColor} from '@ohoareau/css-utils';
import {useFormContext} from 'react-hook-form';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    WarningPanel,
    Clickable,
    PromoCodeForm,
    Spinner,
    useLuniiUser,
    ErrorPanel,
    useShoppingCart,
    useLuniiNavigation,
    convertPrice,
    Button,
    Checkbox,
    useLuniiTranslation,
    useShoppingCartShippingMethodsFindSubmit,
    usePurchasePaymentFreeCreateSubmit,
    usePurchasePaymentCreateSubmit,
    payment_type,
    PaymentTypeEnum,
    ProductTableNameEnum, shipping_method, removed_items, AcceptedCartItemRemovalReason,
} from '../../ui';
import {useStripePayment, usePaypalPayment, useFreePayment} from '../../hooks';
import {availableForSubscription} from '../../../configs/site';
import {CheckoutCartTotal, CheckoutCartProducts} from '.';
import {Alert} from './v2';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        paddingTop: theme.spacing(2),
    },
    nbItems: {
        ...cssText(theme, 'standard', 'overline_title_1', undefined, undefined, '#2793BA'),
        marginBottom: theme.spacing(1 / 2),
    },
    title: {
        ...cssText(theme, 'standard', 'title_1', undefined, undefined, '#063646'),
    },
    promoCode: {
        margin: theme.spacing(0, 0, 3, 0),
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    cartTotal: {
        marginTop: theme.spacing(0),
    },
    divider: {
        margin: theme.spacing(2, 0),
        backgroundColor: '#C8E7F9',
        height: 1,
    },
    action: {
        marginTop: 24,
    },
    actionButton: {
        width: '100%',
        display: 'flex',
    },
    conditions: {
        '& label': {
            color: '#5D90A3',
        },
    },
    checkbox: {
        marginBottom: 18,
    },
    mobileTitle: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            ...cssText(theme, 'standard', 'title_2'),
            marginBottom: 16,
        },
    },
    noShippingMethod: {
        ...cssBox(theme, 'standard', 'danger'),
        ...cssText(theme, 'standard', 'caption', undefined, undefined, 'white'),
        padding: 8,
        marginTop: theme.spacing(3),
    },
    legal: {
        display: 'inline',
        ...cssFgColor(theme, 'standard', 'link'),
    },
    errors: {
        marginBottom: theme.spacing(2),
    },
    error: {
        margin: theme.spacing(0, 0, 2, 0),
        '&:last-child': {
            margin: 0,
        },
    },
    spinner: {
        height: 300,
    },
    spinnerText: {
        ...cssText(theme, 'standard', 'title_2'),
        padding: theme.spacing(0, 2),
        textAlign: 'center',
    },
    warning: {
        marginTop: '16px !important',
    },
    removedItem: {
        fontStyle: 'italic',
        fontWeight: 800,
    }
}));

export function PaymentLeft({
    cardStatus = false,
    cardRegister = false,
    setLoading,
    loading,
    paymentType,
    onCartError,
    onEditDeliveryAddress = false,
    onEditBillingAddress = false,
}: PaymentLeftProps) {
    const classes = useStyles();
    const {goPageByModel} = useLuniiNavigation();
    const {t, i18n, exists} = useLuniiTranslation();
    const {user} = useLuniiUser();
    const [
        {cart, saveCart, validateCart, invalidateCart},
        {
            saveCart: {loading: loadingSaveCart, error: errorSaveCart, ...infosSavedCart},
            validateCart: {error: errorValidateCart},
            invalidateCart: {
                loading: loadingInvalidateCart,
                error: errorInvalidateCart,
                ...infosInvalidateCart
            },
        },
    ] = useShoppingCart();
    const [
        findShippingMethods,
        {error: errorShippingMethodFind, data: shippingMethods, loading: loadingShippingMethods},
    ] = useShoppingCartShippingMethodsFindSubmit();
    const findLocaleAddress = useCallback(
        (a) => {
            const address = a.find((address: any) => address.locale === (cart || {}).country);
            return (address && {...address}) || null;
        },
        [cart],
    );
    const hasHardware = useMemo(
        () =>
            ((cart || {}).items || []).some(
                (item: any) => item.type === ProductTableNameEnum.Hardware,
            ),
        [cart],
    );
    const hardwareOnly = useMemo(
        () =>
            ((cart || {}).items || []).every(
                (item: any) => item.type === ProductTableNameEnum.Hardware,
            ),
        [cart],
    );
    const billingInfos = useMemo(() => findLocaleAddress((user || {}).billingInfos), [user]);
    const deliveryInfos = useMemo(() => findLocaleAddress((user || {}).deliveryInfos), [user]);
    // Get cart && persist it remotely
    useEffect(() => {
        cart && saveCart(cart);
    }, []);
    // if the cart contains a pack that is removed by the save cart we must update the checkboxes state
    useEffect(() => {
        hardwareOnly && setCheckboxes((state) => ({...state, withdrawal: true}));
    }, [hardwareOnly]);
    useEffect(() => {
        !loadingSaveCart &&
            infosSavedCart?.called &&
            hasHardware &&
            deliveryInfos &&
            deliveryInfos.phone &&
            !loadingShippingMethods &&
            cart?.country &&
            findShippingMethods(cart.country);
    }, [hasHardware, cart, findShippingMethods, deliveryInfos, loadingShippingMethods]);
    // Checkboxes
    const [checkboxes, setCheckboxes] = useState(() => ({withdrawal: !!hardwareOnly, cgv: false}));
    const handleUpdateCheckboxes = useCallback(
        (e) => {
            const {
                target: {id, checked},
            } = e;
            setCheckboxes((prevState: any) => {
                const state = {...prevState};
                state[id] = checked;
                return state;
            });
        },
        [setCheckboxes],
    );

    const [errorPurchaseIsReady, setErrorPurchaseIsReady] = useState<{[key: string]: boolean}>({});
    const [paymentMethodError, setPaymentMethodError] = useState<any>(undefined);

    // Shipping methods
    const [selectedShippingMethod, setSelectedShippingMethod] = useState<shipping_method|null>(null);
    useEffect(() => {
        shippingMethods &&
            setSelectedShippingMethod(
                shippingMethods.length === 1 ? shippingMethods[0] : shippingMethods[0],
            );
    }, [shippingMethods, setSelectedShippingMethod]);
    useEffect(() => {
        // reinit error fields when cart is updated
        setErrorPurchaseIsReady({});
    }, [cart]);
    useEffect(() => {
        const validateError = errorValidateCart?.graphQLErrors?.find((error: any) => error?.extensions?.exception?.luniiCode?.indexOf('67.') === 0);
        if (validateError && !loadingInvalidateCart && !infosInvalidateCart?.data?.invalidateCart.success && !loadingSaveCart && cart) {
            setLoading(false);
            (async () => {
                await invalidateCart();
                await saveCart(cart);
            })();
        }
    }, [errorValidateCart]);

    // Payment
    const [createPayment, {error: errorPayment}] = usePurchasePaymentCreateSubmit();
    const [createPaymentFree, {error: errorPaymentFree}] = usePurchasePaymentFreeCreateSubmit();
    const {
        formState: {isValid: creditCardOwnerComplete},
        watch,
        trigger,
    } = useFormContext();

    const handleLegalClick = useCallback(
        (e) => {
            e.preventDefault();
            goPageByModel && goPageByModel('mentions_legales', true);
        },
        [goPageByModel],
    );

    const handleCheckPaymentIsReady = useCallback(async () => {
        await trigger();
        const stepsStatus = {
            payment_validation_delivery_infos: false,
            payment_validation_billing_infos: false,
            payment_validation_payment_method:
                paymentType?.method === PaymentTypeEnum.PAYPAL ||
                paymentType?.method === PaymentTypeEnum.STRIPE_REGISTERED ||
                !!(
                    cart?.items &&
                    cart.items.length > 0 &&
                    (cart.price <= 0 || (typeof cart.leftToPay?.totalWithVat === 'number' && cart.leftToPay?.totalWithVat <= 0))
                ), // méthode de paiement paypal, stripe registered ou gratuit
            payment_validation_terms: false,
        };
        if (!onEditDeliveryAddress && ((deliveryInfos && selectedShippingMethod && deliveryInfos.phone) || !hasHardware)) {
            stepsStatus.payment_validation_delivery_infos = true;
        }
        if (billingInfos && !onEditBillingAddress) {
            stepsStatus.payment_validation_billing_infos = true;
        }
        if (cardStatus && creditCardOwnerComplete) {
            stepsStatus.payment_validation_payment_method = true;
        }
        if (Object.values(checkboxes).every((x) => x)) {
            stepsStatus.payment_validation_terms = true;
        }
        setErrorPurchaseIsReady(stepsStatus);
        return Object.values(stepsStatus).every((x) => x);
    }, [
        hasHardware,
        deliveryInfos,
        selectedShippingMethod,
        billingInfos,
        cardStatus,
        creditCardOwnerComplete,
        trigger,
        checkboxes,
        onEditDeliveryAddress,
        onEditBillingAddress,
        paymentType,
    ]);

    const stripe = useStripe();
    const elements = useElements();

    const [createPaypalPayment, validatePaypalPayment] = usePaypalPayment(createPayment);
    const [createStripeUnregisteredPayment, createStripeRegisteredPayment, validateStripePayment] =
        useStripePayment(createPayment, stripe, elements);
    const [createFreePayment, validateFreePayment] = useFreePayment(createPaymentFree);

    const handlePurchaseClick = useCallback(async () => {
        if (loading) return;
        setLoading(true);

        const onError = async (error?: any) => {
            if (error) setPaymentMethodError(error);
            setLoading(false);
            await invalidateCart();
            await saveCart(cart);
        };

        try {
            const paymentIsReady = await handleCheckPaymentIsReady();
            if (!paymentIsReady) {
                setLoading(false);
                return;
            }

            setPaymentMethodError(undefined);

            const purchase = await validateCart(
                hasHardware ? (selectedShippingMethod || {}).id || undefined : undefined,
            );

            if (!purchase || !purchase.id) {
                return;
            }

            let payment;

            if (
                cart?.items &&
                cart.items.length > 0 &&
                (cart.price <= 0 || (typeof cart.leftToPay?.totalWithVat === 'number' && cart.leftToPay?.totalWithVat <= 0))
            ) {
                payment = await createFreePayment(purchase.id);
            } else if (paymentType?.method === PaymentTypeEnum.STRIPE_UNREGISTERED) {
                const cardOwner = watch('cardOwner');
                payment = await createStripeUnregisteredPayment(
                    purchase.id,
                    cart,
                    onError,
                    billingInfos,
                    cardOwner,
                    cardRegister,
                );
            } else if (paymentType?.method === PaymentTypeEnum.STRIPE_REGISTERED) {
                payment = await createStripeRegisteredPayment(
                    purchase.id,
                    cart,
                    onError,
                    billingInfos,
                    paymentType?.card,
                );
            } else if (paymentType?.method === PaymentTypeEnum.PAYPAL) {
                payment = await createPaypalPayment(purchase.id, cart, onError);
            }

            if (!payment) {
                await onError('payment is null');
                return;
            }

            if (
                cart?.items &&
                cart.items.length > 0 &&
                (cart.price <= 0 || (typeof cart.leftToPay?.totalWithVat === 'number' && cart.leftToPay?.totalWithVat <= 0))
            ) {
                await validateFreePayment(purchase.id, cart, payment, onError);
            } else if (
                payment.stripe &&
                (paymentType?.method === PaymentTypeEnum.STRIPE_UNREGISTERED ||
                    paymentType?.method === PaymentTypeEnum.STRIPE_REGISTERED)
            ) {
                await validateStripePayment(purchase.id, cart, payment.stripe, onError);
            } else if (payment.paypal && paymentType?.method === PaymentTypeEnum.PAYPAL) {
                await validatePaypalPayment(payment.paypal);
            }
        } catch (e) {
            await onError(e);
        }
    }, [
        loading,
        setLoading,
        handleCheckPaymentIsReady,
        setPaymentMethodError,
        hasHardware,
        selectedShippingMethod,
        cart,
        validateCart,
        billingInfos,
    ]);

    if (!cart || !cart.items || cart.items.length === 0)
        onCartError({removedItems: cart?.removedItems});

    const itemsQuantity = ((cart || {}).items || []).reduce(
        (q: number, item: any) => item.quantity + q,
        0,
    );
    const cgvTrans = (
        <Trans
            i18nKey="checkout_shipping_and_purchase_cgv"
            i18n={i18n}
        >
            ...
            <Clickable
                className={classes.legal}
                onClick={handleLegalClick}
            >
                ...
            </Clickable>
        </Trans>
    );
    const totalPrice = (selectedShippingMethod?.price || 0) + (cart?.leftToPay?.totalWithVat || 0);
    const showSubscriptionMessage =
        !!user?.subscription &&
        availableForSubscription(cart?.country) &&
        cart?.items?.some((item: any) => item.type === ProductTableNameEnum.Packs);
    const handlePromoCodeUpdate = () => {
        saveCart(cart);
    };
    // disable promo code form only if total price is equal to 0 without discount code applied
    const disablePromoCodeForm = totalPrice === 0 && !(cart?.appliedDiscountAmount);
    useEffect(() => {
        if (errorSaveCart
            || errorPayment
            || errorPaymentFree
            || errorValidateCart
            || errorShippingMethodFind
            || paymentMethodError) setLoading(false);
    }, [errorSaveCart, errorPayment, errorPaymentFree, errorValidateCart, errorShippingMethodFind, paymentMethodError, setLoading]);
    return (
        <div className={classes.root}>
            {(loadingSaveCart || loadingInvalidateCart) && (
                <div className={classes.spinner}>
                    <Spinner opacity={1} bgColor='#F3FBFD'>
                        <div className={classes.spinnerText}>{t('checkout_payment_get_cart')}</div>
                    </Spinner>
                </div>
            ) || false}
            {!loadingSaveCart && !loadingInvalidateCart && <div>
                <div>
                    <PromoCodeForm
                        disabled={disablePromoCodeForm}
                        hasDiscount={!!(cart?.discount || cart?.productsDiscount)}
                        className={classes.promoCode}
                        onPromoCodeAdded={handlePromoCodeUpdate}
                    />
                    {itemsQuantity && (
                        <div>
                            <div className={classes.nbItems}>
                                {itemsQuantity} {t('checkout_cart_header_nb_items')}
                            </div>
                            <div className={classes.title}>
                                {t('checkout_cart_header_title')}
                            </div>
                        </div>
                    ) || false}
                    <CheckoutCartProducts items={cart?.items} locale={cart?.country}/>
                    {exists('checkout_cart_global_message') && (
                        <WarningPanel
                            className={classes.warning}
                            type="message"
                            transKey="checkout_cart_global_message"
                        />
                    ) || false}
                    {hasHardware && exists('checkout_cart_warning_hardware') && (
                        <WarningPanel
                            className={classes.warning}
                            type="message"
                            transKey="checkout_cart_warning_hardware"
                        />
                    ) || false}
                    {showSubscriptionMessage && exists('checkout_cart_subscription_warning') && (
                        <WarningPanel
                            className={classes.warning}
                            type="message"
                            transKey="checkout_cart_subscription_warning"
                        />
                    ) || false}
                    {shippingMethods && shippingMethods.length === 0 && hasHardware && (
                        <div className={classes.noShippingMethod}>
                            {t('checkout_error_no_shipping_methods_available')}
                        </div>
                    ) || false}
                    {(cart?.removedItems || [])
                        .filter((removedItem: removed_items) => AcceptedCartItemRemovalReason.indexOf(removedItem.code) !== -1)
                        .map((removedItem: removed_items) => (
                            <Alert
                                className={classes.warning}
                                theme='error'
                                description={(
                                    <div>
                                        <div>{t(`checkout_cart_removed_items_${(removedItem.code || '')}`)}</div>
                                        {removedItem.items?.filter(item => item.id).map((item) => (
                                            <div className={classes.removedItem}>{`"${item.name || item.id}"`}</div>
                                        ))}
                                    </div>
                                )}
                            />
                    ))}
                    <CheckoutCartTotal
                        shippingMethod={selectedShippingMethod}
                        cart={cart}
                        className={classes.cartTotal}
                        mode="payment"
                        hasHardware={hasHardware}
                        totalPrice={totalPrice}
                        onPromoCodeUpdate={handlePromoCodeUpdate}
                    />
                </div>
                <div className={classes.divider} />
                {(errorSaveCart
                    || errorPayment
                    || errorPaymentFree
                    || errorValidateCart
                    || errorShippingMethodFind
                    || paymentMethodError
                    || Object.entries(errorPurchaseIsReady || {}).length) && (
                    <div className={classes.errors}>
                        {errorSaveCart && (
                            <ErrorPanel
                                className={classes.error}
                                error={errorSaveCart}
                                group="saveCart"
                            />
                        ) || false}
                        {errorPayment && (
                            <ErrorPanel
                                className={classes.error}
                                error={errorPayment}
                                group="createPurchasePayment"
                            />
                        ) || false}
                        {errorPaymentFree && (
                            <ErrorPanel
                                className={classes.error}
                                error={errorPaymentFree}
                                group="createPurchasePayment"
                            />
                        ) || false}
                        {errorValidateCart && (
                            <ErrorPanel
                                className={classes.error}
                                error={errorValidateCart}
                                group="validateCart"
                            />
                        ) || false}
                        {errorShippingMethodFind && hasHardware && (
                            <ErrorPanel
                                className={classes.error}
                                error={errorShippingMethodFind}
                                group="shippingMethod"
                            />
                        ) || false}
                        {paymentMethodError && (
                            <ErrorPanel
                                className={classes.error}
                                error={paymentMethodError}
                                group="stripe"
                            />
                        ) || false}
                        {Object.entries(errorPurchaseIsReady).map(
                            ([key, status]) =>
                                !status && (
                                    <ErrorPanel
                                        className={classes.error}
                                        key={key}
                                        error={{code: key}}
                                    />
                                ),
                        )}
                    </div>
                ) || false}
                <div className={classes.conditions}>
                    {!hardwareOnly && (
                        <Checkbox
                            alignItems="flex-start"
                            className={classes.checkbox}
                            status={(checkboxes || {}).withdrawal}
                            id="withdrawal"
                            content={t('checkout_shipping_and_purchase_withdrawal')}
                            onChange={handleUpdateCheckboxes}
                            name="withdrawal"
                        />
                    ) || false}
                    <Checkbox
                        alignItems="flex-start"
                        status={(checkboxes || {}).cgv}
                        id="cgv"
                        content={cgvTrans}
                        onChange={handleUpdateCheckboxes}
                        name="cgv"
                    />
                </div>
                <div className={classes.action}>
                    {cart && (
                        <Button
                            className={classes.actionButton}
                            disabled={loading}
                            onClick={handlePurchaseClick}
                            color="primary"
                            size="large"
                        >
                            {t('checkout_shipping_and_purchase_pay', {
                                price: convertPrice(totalPrice, cart.currency),
                            })}
                        </Button>
                    ) || false}
                </div>
            </div> || false}
        </div>
    );
}

export interface PaymentLeftProps {
    cardStatus?: boolean;
    cardRegister?: boolean;
    setLoading?: any;
    loading: boolean;
    paymentType?: payment_type;
    onCartError: Function;
    onEditDeliveryAddress?: boolean;
    onEditBillingAddress?: boolean;
}

export default PaymentLeft;
